/* 
 * Copyright (C) SEARCH7 Ltd (https://search7.com.au) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import { Column, EditableCell2, FocusedCellCoordinates, RowHeaderCell, Table2 } from "@blueprintjs/table";
import _ from 'lodash';
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { Button } from "@blueprintjs/core";
import { EtrSchedule } from "branch/branch.entities";
import { InfoTooltip } from "common/components";
import { Form, FormField, NumericField } from "common/components/form";
import { EntityFormProps } from "common/components/form/Form";
import { useFieldName, useIsFieldHidden, weekdaysShort } from "common/utils";
import styles from "./styles.module.scss";


export default function <T extends EtrSchedule>({
  className, readOnly, value, errors, onChange,
  children, ...rest
}: EtrScheduleFormProps<T>) {
  const { t } = useTranslation();

  const classes = useMemo(() => [styles.etrScheduleForm, className].join(" "), [className]);
  const fieldName = useFieldName(rest);
  const isHidden = useIsFieldHidden(rest);
  const tableKey = useMemo(() => Math.random().toString(),
    [value?.mon, value?.tue, value?.wed, value?.thu, value?.fri, value?.sat, value?.sun]);
  const [focusedCell, setFocusCell] = useState<FocusedCellCoordinates>();

  return (
    <Form className={classes} {...rest}>
      <NumericField
        name={fieldName("defaultEtr")}
        label={["EtrSchedule.defaultEtr"]}
        value={value?.defaultEtr}
        error={errors?.defaultEtr}
        readOnly={readOnly}
        hidden={isHidden("defaultEtr")}
        onChange={onChange}
        stepSize={1}
        majorStepSize={10}
        minorStepSize={1}
        actions={
          <InfoTooltip
            content={['EtrSchedule.defaultEtrHint']}
            position="top-right"
            iconProps={{ style: { marginBottom: 1 } }} />
        }
      />
      <FormField
        className={styles.delaysTable}
        label={["EtrSchedule.delays"]}
        hidden={isHidden('delays')}
        actions={
          <>
            <Button
              minimal
              icon='cross'
              hidden={
                value?.mon == null &&
                value?.tue == null &&
                value?.wed == null &&
                value?.thu == null &&
                value?.fri == null &&
                value?.sat == null &&
                value?.sun == null
              }
              onClick={() => {
                onChange(
                  ...weekdaysShort.map(weekday => ({
                    target: {
                      name: fieldName(`${weekday}`),
                      value: null,
                    },
                  })))
              }}
            />
            <InfoTooltip
              content={['EtrSchedule.delaysHint']}
              position="top-right"
              iconProps={{ style: { marginBottom: 2.5, marginLeft: 5 } }} />
          </>
        }>
        <Table2
          key={tableKey}
          numRows={24}
          defaultColumnWidth={51}
          enableFocusedCell={true}
          onFocusedCell={(cell) => setFocusCell(cell)}
          focusedCell={focusedCell}
          rowHeaderCellRenderer={(row) => (
            <RowHeaderCell>
              <span style={{ fontSize: 12, whiteSpace: 'nowrap', margin: '0px 5px' }}>
                {`${_.padStart(row.toString(), 2, '0')}:00 - ${_.padStart((row + 1).toString(), 2, '0')}:00`}
              </span>
            </RowHeaderCell>
          )}>
          {weekdaysShort.map(weekday => (
            <Column
              id={weekday}
              key={weekday}
              name={t(`WeekdaysShort.${weekday}`)}
              cellRenderer={(row, col) => {
                return (
                  <EditableCell2
                    value={_.get(value, `${weekday}.delays.${row}`, '') as string}
                    rowIndex={row}
                    columnIndex={col}
                    onConfirm={(val, row) => {
                      if (row != null) {
                        let newVal: number | null = parseInt(val);
                        newVal = isNaN(newVal) || newVal === 0 ? null : newVal;
                        const newDelays = _.get<EtrSchedule, any, number[]>(value, `${weekday}.delays`, new Array(24).fill(null)).slice();
                        if (newDelays[row] !== newVal) {
                          newDelays[row] = newVal;
                          onChange({
                            target: {
                              name: fieldName(`${weekday}.delays`),
                              value: newDelays,
                            }
                          });
                        }
                      }
                    }}
                  />
                );
              }}
            />
          ))}
        </Table2>
      </FormField>
      {children}
    </Form >
  );
}

export type EtrScheduleFormProps<T extends EtrSchedule> = EntityFormProps<T> & {
};